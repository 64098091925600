<template>
  <ion-row class="ion-margin-top">
    <ion-col size="auto">
      <ion-label style="margin-left: 8px">
        Filtrar por:
      </ion-label>
      <ion-radio-group v-model="filtrarPor">
        <ion-row class="ion-align-items-center">
          <ion-col size="auto">
            <ion-label style="margin-right: 8px">Regional</ion-label>
            <ion-radio
              @click="filtrarPor = 'Regional'"
              value="Regional"
            ></ion-radio>
          </ion-col>
          <ion-col size="auto">
            <ion-label style="margin-right: 8px">Nodo</ion-label>
            <ion-radio @click="filtrarPor = 'Nodo'" value="Nodo"></ion-radio>
          </ion-col>
        </ion-row>
      </ion-radio-group>
    </ion-col>
    <ion-col v-if="filtrarPor === 'Regional'">
      <ion-item lines="full">
        <ion-label position="floating" class="ion-margin-top">
          Regional
        </ion-label>
        <ion-select
          :value="selectedRegional"
          @ionChange="selectedRegional = $event.target.value"
        >
          <ion-select-option
            v-for="reg in regionales"
            :value="reg"
            :key="reg._id"
          >
            {{ reg.nombre }}
          </ion-select-option>
        </ion-select>
      </ion-item>
    </ion-col>
    <ion-col v-if="filtrarPor === 'Nodo'">
      <ion-item lines="full">
        <ion-label position="floating" class="ion-margin-top">
          Nodo
        </ion-label>
        <ion-select
          :value="selectedNodo"
          @ionChange="selectedNodo = $event.target.value"
        >
          <ion-select-option v-for="n in nodos" :value="n" :key="n">
            {{ n }}
          </ion-select-option>
        </ion-select>
      </ion-item>
    </ion-col>
  </ion-row>
  <ion-row class="ion-align-items-center">
    <ion-col>
      <ion-item lines="full">
        <ion-label position="floating" class="ion-margin-top">
          Campañas
        </ion-label>
        <ion-select
          :value="selectedCampania"
          @ionChange="selectedCampania = $event.target.value"
        >
          <ion-select-option
            v-for="camp in campanias"
            :value="camp"
            :key="camp._id"
          >
            {{ camp.nombre }}
          </ion-select-option>
        </ion-select>
      </ion-item>
    </ion-col>
  </ion-row>
  <ion-tabs>
    <ion-tab-bar slot="top" :selected-tab="selectedTipoPlaga">
      <ion-tab-button @click="selectedTipoPlaga = 'Maleza'" tab="Maleza">
        <ion-img src="assets/icon/plagas/icoPlgMaleza.png" style="height:70%" />
        <ion-label>Maleza</ion-label>
      </ion-tab-button>
      <ion-tab-button @click="selectedTipoPlaga = 'Insecto'" tab="Insecto">
        <ion-img
          src="assets/icon/plagas/icoPlgInsecto.png"
          style="height:70%"
        />
        <ion-label>Insecto</ion-label>
      </ion-tab-button>
      <ion-tab-button
        @click="selectedTipoPlaga = 'Enfermedad'"
        tab="Enfermedad"
      >
        <ion-img src="assets/icon/plagas/icoPlgEnferm.png" style="height:70%" />
        <ion-label>Enfermedad</ion-label>
      </ion-tab-button>
    </ion-tab-bar>
  </ion-tabs>
  <div v-if="selectedCampania && selectedTipoPlaga && displayChart && isData">
    <BarChart
      :titulo="'Área afectada por ' + selectedTipoPlaga + plural"
      :labels="plagas"
      :dataLabel="dataLabel"
      :dataValues="dataValues"
      backgroundColor="rgba(26, 55, 239, 0.5)"
    />
  </div>
  <div v-if="displayChart && !isData">
    <ion-text style="margin-top: 16px; margin-left: 16px">
      No hay datos de Plagas coincidentes con la búsqueda
    </ion-text>
  </div>
  <ion-button
    @click="volver"
    class="ion-margin-top ion-float-end ion-margin-bottom"
    color="primary"
    v-if="!isAdminAapresid"
  >
    Volver
  </ion-button>
  <ion-row class="ion-align-items-center ion-margin-top" v-if="isAdminAapresid">
    <ion-col>
      <ion-button @click="generateExcel" class="ion-float-start" fill="clear">
        <ion-icon
          :md="icons.excel"
          :ios="icons.excel"
          color="secondary"
          size="large"
        ></ion-icon>
      </ion-button>
    </ion-col>
    <ion-col size="auto">
      <ion-button @click="volver" class="ion-float-end" color="primary">
        Volver
      </ion-button>
    </ion-col>
  </ion-row>
</template>

<script>
import {
  IonRow,
  IonCol,
  IonRadio,
  IonRadioGroup,
  IonItem,
  IonLabel,
  IonButton,
  IonTabBar,
  IonTabButton,
  IonImg,
  alertController,
  IonSelect,
  IonSelectOption,
  IonText,
  IonIcon
} from "@ionic/vue";
import { mapGetters, mapState } from "vuex";
import http from "@/api/http";
import { Regionales, Campanias } from "@/api";
import BarChart from "@/components/charts/BarChart.vue";
import dateFormat from "@/utils/dateFormat";
import { readerOutline } from "ionicons/icons";

export default {
  components: {
    IonItem,
    IonButton,
    IonRadio,
    IonRadioGroup,
    IonRow,
    IonCol,
    IonTabBar,
    IonTabButton,
    IonImg,
    IonLabel,
    IonSelect,
    IonSelectOption,
    BarChart,
    IonText,
    IonIcon
  },
  middleware: ["authenticated", "auth_cliente"],
  data: () => ({
    selectedRegional: { _id: "0", nombre: "" },
    selectedNodo: "",
    selectedCampania: undefined,
    filtrarPor: "Regional",
    selectedTipoPlaga: "",
    displayChart: false,
    dataLabel: "Hectáreas",
    dataValues: [],
    regionales: [],
    campanias: [],
    plagas: [],
    tiposPlagas: ["Enfermedad", "Insecto", "Maleza"],
    nodos: [
      "Todos",
      "Litoral",
      "Centro",
      "Sur",
      "O.M.",
      "Oeste",
      "Internacional"
    ],
    icons: {
      excel: readerOutline
    }
  }),

  computed: {
    ...mapGetters(["isAdmin"]),
    ...mapState(["admin_user"]),
    isAdminAapresid() {
      return this.isAdmin && this.admin_user.roles[0] === "ADMIN_AAPRESID";
    },
    isData() {
      return this.plagas.length ? true : false;
    },
    plural() {
      if (this.selectedTipoPlaga === "Enfermedad") {
        return "es";
      }
      return "s";
    }
  },

  watch: {
    filtrarPor() {
      this.selectedRegional = { _id: "0", nombre: "" };
      this.selectedNodo = "";
    },
    selectedTipoPlaga() {
      this.updateChart();
    }
  },

  async mounted() {
    await this.dataSync();
  },

  methods: {
    async dataSync() {
      this.regionales = await this.getRegionales();
      this.campanias = await this.getCampanias();
      this.$nextTick(() => {
        this.selectedCampania = this.campanias.find(c => c.esActual === true);
      });
    },

    async updateChart() {
      if (this.selectedCampania && this.selectedTipoPlaga) {
        const { labels, values } = await this.getBarChartData();
        this.plagas = labels;
        this.dataValues = values;
        this.displayChart = true;
      } else {
        const alert = await alertController.create({
          header: "Error",
          message:
            "Debe elegir una Campaña y un Tipo de Plaga para poder continuar.",
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        alert.present();
      }
    },

    async getRegionales() {
      const { data, error } = await Regionales.get({
        sort: { nombre: 1 }
      });
      if (error) {
        const alert = await alertController.create({
          header: "Error",
          message: "Error obteniendo regionales.",
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        return alert.present();
      }
      data.unshift({ _id: "0", nombre: "Todas" });
      return data;
    },

    async getCampanias() {
      const { data, error } = await Campanias.get({
        sort: { nombre: 1 }
      });
      if (error) {
        const alert = await alertController.create({
          header: "Error",
          message: "Error obteniendo Campañas.",
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        return alert.present();
      }
      return data;
    },

    async generateExcel() {
      if (!this.selectedCampania) {
        const alert = await alertController.create({
          header: "Error",
          message: "Debe elegir una Campaña para poder continuar.",
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        alert.present();
      } else {
        const { data } = await http.get("/api/plaga-sembrada-get-xlsx", {
          params: {
            campania: this.selectedCampania,
            regional: { _id: "0", nombre: "" },
            nodo: "Todos",
            tipoPlaga: undefined
          }
        });
        const mimeType =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        const campaniaTxt = this.selectedCampania
          ? this.selectedCampania.nombre + "_"
          : "";
        const fileName =
          "Informe_Plagas_" +
          campaniaTxt +
          "-_" +
          dateFormat(new Date(), "dd-MM-yyyy") +
          "_-_" +
          dateFormat(new Date(), "HH-mm") +
          ".xlsx";
        fetch(`data:${mimeType};base64,${data}`)
          .then(res => res.blob())
          .then(blob => new File([blob], fileName, { type: mimeType }))
          .then(doc => {
            const url = (window.URL || window.webkitURL).createObjectURL(doc);
            let a = document.createElement("a");
            a.href = url;
            a.download = fileName;
            a.dataset.downloadurl = `${mimeType}:${a.download}:${a.href}`;
            document.body.appendChild(a);
            a.click();
            a.remove();
            return url;
          });
      }
    },

    async getBarChartData() {
      const { data, error } = await http.get(
        "/api/area-sembrada-plaga-ha-data",
        {
          params: {
            campania: this.selectedCampania,
            regional: this.selectedRegional,
            tipoPlaga: this.selectedTipoPlaga,
            nodo: this.selectedNodo
          }
        }
      );
      if (error) {
        const alert = await alertController.create({
          header: "Error",
          message: "Error calculando las Hectáreas por Plaga.",
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        return alert.present();
      }
      const labels = data.map(d => d.plaga);
      const values = data.map(d => d.hectareas);
      return { labels, values };
    },

    volver() {
      // this.$router.replace({ name: "informes" });
      this.$router.back();
    }
  }
};
</script>

<style scoped>
ion-tab-button {
  --ripple-color: var(--ion-color-primary);
  --color: var(--ion-color-dark);
  --color-selected: var(--ion-color-primary);
}

ion-tab-button.tab-selected {
  --background: var(--ion-color-primary) !important;
  --ripple-color: var(--ion-color-light);
  --color-selected: var(--ion-color-dark);
}

ion-tabs {
  max-height: 150px;
}

ion-tab-bar {
  justify-content: center;
}
</style>
